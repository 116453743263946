import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import { Table } from '@autovia-uk/polaris-components/components/organisms/Table';
import { Link } from '@autovia-uk/polaris-components/components/molecules/Link';

const SpecListItem = ({
  listItem,
}) => {
  const {
    fuelType,
    name,
    items,
  } = listItem;

  return (
    <>
      <Table
        mode="stack"
        extraClassNames={{
          '-specs': true,
        }}
      >
        <table>
          <caption>{name}</caption>
          <thead>
            <tr>
              <th>MODEL</th>
              <th>FUEL</th>
              <th>
                { fuelType === 'Electric' ? 'BATTERY CAPACITY' : 'MPG' }
              </th>
              <th>
                { fuelType === 'Electric' ? 'RANGE' : 'CO2' }
              </th>
              <th>0-62MPH</th>
              <th>TOP SPEED</th>
              <th>PRICE</th>
            </tr>
          </thead>
          <tbody>
            { items.map((item, itemSpecIndex) => (
              <tr key={`itemSpec_${itemSpecIndex}`}>
                <td><Link href={item.path} extraClassNames={{ 'polaris__car-name--link': true }}>{item.name}</Link></td>
                <td>{item.fuel.value}</td>
                <td>{item.fuel.value === 'Electric' ? item.batteryCapacity.value : item.mpg.value}</td>
                <td>{item.fuel.value === 'Electric' ? item.range.value : item.co2.value}</td>
                <td>{item.acceleration.value}</td>
                <td>{item.topSpeed.value}</td>
                <td>{item.price?.value ? `£${item.price.value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : ''}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Table>
    </>
  );
};

const SpecList = ({
  vehicleSpecList,
}) => (
  <Fragment>
    {vehicleSpecList.map(listItem => <SpecListItem listItem={listItem} key={uniqid()} />)}
  </Fragment>
);

SpecListItem.propTypes = {
  listItem: PropTypes.shape({
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    fuelType: PropTypes.string.isRequired,
  }).isRequired,
};

SpecList.propTypes = {
  vehicleSpecList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SpecList;
