import React, { Fragment } from 'react';
import uniqid from 'uniqid';
import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { Collapsible } from '@autovia-uk/polaris-components/components/molecules/Collapsible';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { Table } from '@autovia-uk/polaris-components/components/organisms/Table';

import { vehicleSpecsPropsShape } from './props-shape';

/**
 * Convert camelCaseText to Sentence Case Text
 */
export function formatString(string) {
  const result = string.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

const VehicleSpecs = ({
  vehicleSpecs,
}) => {
  const {
    summary,
    technicalData,
    standardEquipment,
  } = vehicleSpecs;

  const renderSummary = () => (
    <Table mode="swipe" key={uniqid('summary-')}>
      <table>
        <tbody>
          {Object.entries(summary).map(([key, value]) => {
            let tableHeader;
            switch (key) {
              case 'CC':
                tableHeader = 'Engine Capacity - CC';
                break;
              case 'co2':
                tableHeader = 'WLTP - CO2 (g/km) - Comb';
                break;
              case 'bhp':
                tableHeader = 'Engine Power - BHP';
                break;
              case 'mpg':
                tableHeader = 'WLTP - MPG - Comb';
                break;
              case 'luggageUp':
                tableHeader = 'Luggage Capacity (Seats Up)';
                break;
              case 'kerbweightMin':
                tableHeader = 'Minimum Kerbweight';
                break;
              case 'aer':
                tableHeader = key.toUpperCase();
                break;
              default:
                tableHeader = formatString(key);
                break;
            }

            const incorrectValues = ['null mm', 'null l', 'Not Available mm', 'null kg', 'VehicleSummary'];

            if ((value
                && (value === ''
                  || incorrectValues.includes(value)
                  || (key === 'price' && value === null))
            ) || (typeof value === 'object' && !value)
            ) {
              return false;
            }

            return (
              <tr key={uniqid()}>
                <td>{tableHeader}</td>
                <td>
                  {key === 'price'
                    ? `£${value
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                    : value}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Table>
  );

  const renderTechnicalData = () => technicalData
    .map(({ group: groupTitle, items }) => (
      <Fragment key={uniqid('technical-data-')}>
        <Heading size={2} extraClassNames={{ h3: true }}>{groupTitle}</Heading>
        <Table mode="stack">
          <table>
            <tbody>
              { items.map((item, index) => (
                <tr key={index}>
                  <td>{item.key}</td>
                  <td>{item.value}</td>
                </tr>
              )) }
            </tbody>
          </table>
        </Table>
      </Fragment>
    ));

  const renderEquipmentData = () => standardEquipment
    .map(({ group: groupTitle, items }) => (
      <Fragment key={uniqid('standard-equipment-')}>
        <Heading size={2} extraClassNames={{ h3: true }}>{groupTitle}</Heading>
        <ul>
          { items.map((item, index) => (
            <li key={index}>{item}</li>
          )) }
        </ul>
      </Fragment>
    ));

  return (
    <>
      <div className="polaris__simple-grid--main">
        <Collapsible
          key={uniqid()}
          title="Summary"
          content={renderSummary()}
          isOpen
          iconOpen="polaris__ico-down-chevron"
          iconClose="polaris__ico-up-chevron"
        />

        <Collapsible
          key={uniqid()}
          title="Equipment"
          content={renderEquipmentData()}
          isOpen
          iconOpen="polaris__ico-down-chevron"
          iconClose="polaris__ico-up-chevron"
        />
      </div>

      <div className="polaris__simple-grid--full">
        <Ad
          key="refresh-inline_1"
          isSpaceReserved
          type="slot-1"
          id="refresh-inline_1"
          targeting={{
            position: 'inline_1',
            placement: 'inline_1',
            refresh: 'yes',
          }}
        />
      </div>

      <div className="polaris__simple-grid-main">
        <Collapsible
          key={uniqid()}
          title="Technical"
          content={renderTechnicalData()}
          isOpen
          iconOpen="polaris__ico-down-chevron"
          iconClose="polaris__ico-up-chevron"
        />
      </div>
    </>
  );
};

VehicleSpecs.propTypes = {
  ...vehicleSpecsPropsShape,
};

VehicleSpecs.defaultProps = {
  vehicleSpecs: {
    summary: [],
    technicalData: [],
    standardEquipment: [],
  },
};

export default VehicleSpecs;
