/**
 * Extracts content from an array of related content.
 * @param {Array} related Array of objects that have a type and content key.
 * @param {String} key    What key to look for. Defaults to the most popular.
 */
export function getRelatedContent({ related, key }) {
  const item = Array.isArray(related) ? related.find(({ type }) => type === key) : false;
  if (!item) {
    return [];
  }

  return item.content;
}
