/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import get from 'lodash.get';
import { compose } from 'recompose';
import classNames from 'classnames';

import { PolarisApp as PolarisContext } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { ErrorBoundary } from '@autovia-uk/polaris-components/components/organisms/ErrorBoundary';
import { setPageTargeting } from '@autovia-uk/polaris-components/sharedHelpers/googlePublisherTag';
import {
  Helmet,
  useBlueConic,
  useCrimtan,
  usePermutive,
} from 'polaris-coreweb/exports';

import { withMeta } from 'Protons';
import { getCustomizedAdConfig } from '@autovia-uk/polaris-components/sharedHelpers/getCustomizedAdConfig';
import { getMappedContentType } from '@autovia-uk/polaris-components/sharedHelpers/getMappedContentType';
import { getOOPAds } from '@autovia-uk/polaris-components/sharedPartials/getOOPAds';
import { pushWebVitalsToDataLayer } from '@autovia-uk/polaris-components/sharedPartials/pushWebVitalsToDataLayer';

import { getMappedLayoutType } from 'Helpers';
import { Header } from '../../organisms/Header';
import { Footer } from '../../organisms/Footer';

import { contextOverride } from './contextOverride';
import { basePropsShape } from './props-shape';

// eslint-disable-next-line react/prop-types
const getBelowHeaderAd = ({
  afterNavigationMobileAds,
  afterNavigationDesktopAds,
  contentType,
}) => {
  if (!afterNavigationDesktopAds && !afterNavigationMobileAds) return null;

  const disableOnTemplate = ['HOMEPAGE', 'error', 'notFound', 'loading'];
  if (disableOnTemplate.includes(contentType)) return null;

  return (
    <div
      className={classNames({
        'polaris-ad--wrapper-mobile': afterNavigationMobileAds,
        'polaris-ad--wrapper-desktop': afterNavigationDesktopAds,
      })}
    >
      <Ad
        {...getCustomizedAdConfig({
          desktopRule: afterNavigationDesktopAds,
          mobileRule: afterNavigationMobileAds,
        })}
        extraClassNames={{
          '-full-width': true,
          '-below-header': true,
          'hide-mobile': !afterNavigationMobileAds,
          'hide-tablet': !afterNavigationMobileAds,
          'hide-desktop': !afterNavigationDesktopAds,
        }}
        id="refresh-below_header"
        isSkippable
        isSpaceReserved
        targeting={{
          position: 'below_header',
          placement: 'below_header',
          refresh: 'yes',
        }}
      />
    </div>
  );
};

const BaseComponent = (props) => {
  const {
    adConfig: pageConfig = {},
    children,
    config,
    config: {
      branding: {
        logoSquarePng,
      },
      globalSettings: {
        blueconicScriptUrl,
        disableBrowserRouter,
        gptTracking,
        headerBidding: {
          amazonHeaderBiddingEnabled,
          amazonPubId,
        },
        permutive: {
          namespace,
          projectId,
          publicApiKey,
        },
      },
      fontsUrl,
    },
    dataLayer,
    metaData,
    showProductSelector,
    type,
  } = props;

  useEffect(() => {
    if (!amazonHeaderBiddingEnabled || !amazonPubId) return;

    window.apstag.init({
      pubID: amazonPubId,
      adServer: 'googletag',
      simplerGPT: true,
    });
  }, []);

  const fullWidth = get(props, 'layoutData.page.fullWidth');

  const mappedLayoutType = getMappedLayoutType(props);
  const isCommercialPage = mappedLayoutType === 'commercialPage';
  const isCommercialPageTitleImage = mappedLayoutType === 'commercialPageTitleImage';

  const ads = get(config, 'ads', {});
  const globalAdSettings = get(config, 'globalSettings.adSettings', {});

  const { targeting, ...adConfig } = {
    ...pageConfig,
    templates: ads,
    globalTargeting: pageConfig.targeting,
  };

  setPageTargeting(targeting);

  const contentType = get(props, 'layoutData.page.contentType', '');
  const mappedContentType = getMappedContentType(props);
  const contentTypeClass = ` polaris__${mappedContentType.toLowerCase()}--template`;

  if (gptTracking && gptTracking === true) {
    if (typeof window !== 'undefined') {
      import('gpt-tracker')
        .then((tracker) => {
          if (window.ga) {
            tracker.init();
          }
        });
    }
  }

  pushWebVitalsToDataLayer();

  useBlueConic(blueconicScriptUrl);
  useCrimtan();
  usePermutive(
    projectId,
    publicApiKey,
    dataLayer,
    namespace,
    config,
  );

  return (
    <>
      <Helmet>
        <link
          rel="preload"
          as="style"
          href={fontsUrl ?? '/fonts/fonts.css'}
        />
        <link
          rel="stylesheet"
          href={fontsUrl ?? '/fonts/fonts.css'}
          media="print"
          onLoad="this.media='all'"
        />
        <noscript dangerouslySetInnerHTML={{
          __html: `
            <link
              rel="stylesheet"
              href="${fontsUrl ?? '/fonts/fonts.css'}"
            />
          `,
        }}
        />
        <link rel="apple-touch-icon" href={logoSquarePng?.src} />
      </Helmet>
      <PolarisContext
        config={{
          ...contextOverride(config, adConfig, type),
          metaData,
          disableBrowserRouter,
        }}
      >
        <div className={`polaris__app ${mappedContentType && contentTypeClass}`}>
          <Ad
            type="page-impression"
            id="page-impression"
            isPageImpression
          />
          <Header stickyEnabled showProductSelector={showProductSelector} />
          {!(isCommercialPage || isCommercialPageTitleImage) && getOOPAds()}
          {
            !fullWidth && !(isCommercialPage || isCommercialPageTitleImage)
            && getBelowHeaderAd({ ...globalAdSettings, contentType })
          }
          <ErrorBoundary forOrganism>
            {children}
          </ErrorBoundary>
          <Footer />
        </div>
      </PolarisContext>
    </>
  );
};

BaseComponent.propTypes = {
  ...basePropsShape,
};

BaseComponent.defaultProps = {
  adConfig: {},
  config: {},
  dataLayer: {},
  metaData: {},
  type: '',
};

const ComposedBaseComponent = compose(withMeta)(BaseComponent);

export default ComposedBaseComponent;
