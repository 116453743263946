import React from 'react';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { getArticleGroup } from '@autovia-uk/polaris-components/sharedPartials/getArticleGroup';
import { getBody } from '@autovia-uk/polaris-components/sharedPartials/getBody';
import { getBreadcrumbs } from '@autovia-uk/polaris-components/sharedPartials/getBreadcrumbs';
import { getGalleryRelatedArticleLink } from '@autovia-uk/polaris-components/sharedPartials/getGalleryRelatedArticleLink';
import { getListGallery } from '@autovia-uk/polaris-components/sharedPartials/getListGallery';

import { getArticleGroupContent } from '@autovia-uk/polaris-components/sharedHelpers/getArticleGroupContent';
import { getMostPopularArticleGroup } from '@autovia-uk/polaris-components/sharedHelpers/getMostPopularArticleGroup';

import { galleryPropsShape } from './props-shape';
import 'Styles/components/templates/_Gallery.scss';

const Gallery = (props) => {
  const {
    config: {
      globalSettings: {
        adSettings: {
          galleryListDesktopAds,
        },
      },
    },
    layoutData: {
      page: {
        associatedContent,
        body,
        breadcrumbs,
        galleryRelatedArticle,
        images,
        isSponsored,
        kicker,
        subtitle,
        title,
      },
    },
  } = props;

  // Add Ad component (rules):
  // - after every 2nd media
  // mediaTypes: image, video, linked media
  // before rendering ads, check if there is enough content on the page and filter rules
  /**
   * Returns an array of even numbers limited by total number of items (totalContent),
   * plus one, which allows rendering the ad after last item if the last item is odd
   * @return {array}
   * @example [2, 4, 6, 8, 10, 12, 14, 16, ..., 2n, ... totalContent]
   */
  // total number of items
  const totalContent = (images && Array.isArray(images)) ? images.length : 0;
  const advertPositions = [];
  for (let n = 1; n < (totalContent + 1); n += 1) {
    if (n % galleryListDesktopAds === 0) {
      advertPositions.push(n);
    }
  }

  const adverts = advertPositions
    .filter(item => item <= totalContent)
    .map((e, i) => ({
      name: 'Ad',
      __typename: 'Advertisement',
      placement: e,
      component: (
        <Ad
          extraClassNames={{
            '-full-width': true,
          }}
          id={`refresh-inline_${i + 1}`}
          isSkippable
          isSpaceReserved
          key={`inline_${i + 1}`}
          label="Advertisement - Gallery continues below"
          targeting={{
            position: `inline_${i + 1}`,
            placement: `inline_${i + 1}`,
            refresh: 'yes',
          }}
          type="slot-1"
        />
      ),
    }));

  const mediaContentWithAds = [...images];

  adverts.map((e, i) => mediaContentWithAds.splice(e.placement + i, 0, e));

  return (
    <>
      <div className="polaris__body">
        <main className="polaris__main" id="main">
          <div className="polaris__core-content polaris__content">

            {getBreadcrumbs({ breadcrumbs, isSponsored, kicker })}

            <Heading size={1} extraClassNames={{ '-title': true }}>
              {title}
            </Heading>
            <Heading size={2} extraClassNames={{ '-subtitle': true }}>
              {subtitle}
            </Heading>

          </div>

          <div className="polaris__core-content polaris__content -body-copy">
            <div className="polaris__simple-grid--full">
              {body && getBody({ bodyInjected: body, template: 'gallery', disableAdInjections: true })}
              {getGalleryRelatedArticleLink(galleryRelatedArticle)}
            </div>
          </div>

          <div className="polaris__core-content polaris__content -gallery">
            {getListGallery(mediaContentWithAds)}
          </div>

        </main>
      </div>

      <div className="polaris__core-content polaris__core-content-first polaris__content">
        {getArticleGroup({
          title: 'Recommended',
          content: getArticleGroupContent({ related: associatedContent, key: 'recommended' }),
          articlesPerRow: 4,
          articleCardProps: { datePosition: 'bottom', headingSize: 4 },
          extraClassNames: { 'polaris__review--consider': true },
        })}

        {getMostPopularArticleGroup({
          articleCardProps: {
            datePosition: 'bottom',
            isHeading: false,
            showRating: true,
          },
          associatedContent,
          nativeAd: 'native_article',
        })}
      </div>
    </>
  );
};

Gallery.propTypes = {
  ...galleryPropsShape,
};

export { Gallery };
