/* eslint-disable react/prop-types */
import React from 'react';
import { VerdictExtra } from '@autovia-uk/polaris-components/components/molecules/VerdictExtra';
import { HtmlSafe } from 'polaris-coreweb/exports';

export const getReviewProCon = (props) => {
  const {
    layoutData: {
      page: {
        pros: rawPros,
        cons: rawCons,
      },
    },
  } = props;

  const toList = (list) => {
    if (list.length > 0) {
      return `<ul>${list.map(item => `<li>${item}</li>`).join('')}</ul>`;
    }
    return null;
  };
  const pros = Array.isArray(rawPros) ? toList(rawPros) : rawPros;
  const cons = Array.isArray(rawCons) ? toList(rawCons) : rawCons;

  if (!pros && !cons) {
    return false;
  }

  return (
    <div className="polaris__pros-cons">
      {pros && (
        <VerdictExtra
          icon="polaris__ico-plus"
          content={(
            <>
              <h2>Pros</h2>
              <HtmlSafe html={pros} />
            </>
          )}
          extraClassNames={{
            'polaris__report-card--pros-container': true,
          }}
        />
      )}
      {cons && (
        <VerdictExtra
          icon="polaris__ico-minus"
          content={(
            <>
              <h2>Cons</h2>
              <HtmlSafe html={cons} />
            </>
          )}
          extraClassNames={{
            'polaris__report-card--pros-container': true,
          }}
        />
      )}
    </div>
  );
};
