import React from 'react';
import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { getCustomizedAdConfig } from '@autovia-uk/polaris-components/sharedHelpers/getCustomizedAdConfig';

/**
 * @name getAfterContentAd
 * @param afterContentDesktopAds
 * @param afterContentMobileAds
 * @returns {JSX.Element}
 */
export const getAfterContentAd = ({ afterContentDesktopAds = null, afterContentMobileAds = null }) => (
  <Ad
    extraClassNames={{
      '-full-width': true,
      '-after-content': true,
      'hide-mobile': !afterContentMobileAds,
      'hide-tablet': !afterContentMobileAds,
      'hide-desktop': !afterContentDesktopAds,
    }}
    id="refresh-after_content_1"
    isNoBackground
    isSkippable
    isSpaceReserved
    key="after_content_1"
    type="slot-1"
    targeting={{
      position: 'after_content_1',
      placement: 'after_content_1',
      refresh: 'yes',
    }}
    {...getCustomizedAdConfig({
      desktopRule: afterContentDesktopAds,
      mobileRule: afterContentMobileAds,
    })}
  />
);
