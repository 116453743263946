import React from 'react';
import { processNodes } from 'react-html-parser';
import he from 'he';
import uniqid from 'uniqid';
import get from 'lodash.get';
import { HtmlSafe } from 'polaris-coreweb/exports';
import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { Image } from '@autovia-uk/polaris-components/components/atoms/Image';
import { ShopWindow } from '@autovia-uk/polaris-components/components/atoms/ShopWindow';
import { Text } from '@autovia-uk/polaris-components/components/atoms/Text';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { IFrame } from '@autovia-uk/polaris-components/components/atoms/IFrame';
import { List } from '@autovia-uk/polaris-components/components/molecules/List';
import { SocialEmbed } from '@autovia-uk/polaris-components/components/molecules/SocialEmbed';
import { RelatedLinks } from '@autovia-uk/polaris-components/components/molecules/RelatedLinks';
import { Gallery } from '@autovia-uk/polaris-components/components/organisms/Gallery';
import { Table } from '@autovia-uk/polaris-components/components/organisms/Table';
import { Video } from '@autovia-uk/polaris-components/components/molecules/Video';
import { Link } from '@autovia-uk/polaris-components/components/molecules/Link';
import { ViewportChecker } from '@autovia-uk/polaris-components/components/protons/ViewportChecker';
import { getContributors } from '@autovia-uk/polaris-components/sharedPartials/getContributors';
import { getDigiteka } from '@autovia-uk/polaris-components/sharedPartials/getDigiteka';
import { Ceros } from '@autovia-uk/polaris-components/components/atoms/Ceros';
import { getNewsletter } from 'SharedPartialsLocal/getNewsletter';
import { getPodcast } from '@autovia-uk/polaris-components/sharedPartials/getPodcast';
import { getPromobox } from '@autovia-uk/polaris-components/sharedPartials/getPromobox';
import { getReviewBlock } from '@autovia-uk/polaris-components/sharedPartials/getReviewBlock';
import { getReviewList } from '@autovia-uk/polaris-components/sharedPartials/getReviewList';
import { getSiteLegalDisclaimer } from '@autovia-uk/polaris-components/sharedPartials/getSiteLegalDisclaimer';
import { getContributorsFromAuthors } from '@autovia-uk/polaris-components/sharedHelpers/getContributorsFromAuthors';

const getClasses = (classesStr) => {
  if (!classesStr) {
    return {};
  }

  return classesStr
    .split(' ')
    .reduce((acc, next) => ({ ...acc, [next]: true }), {});
};

export const getComponentForBlockType = (options) => {
  const {
    index,
    type,
    block,
    data,
    embedTypes,
    mappedContentType,
    layoutType,
  } = options;

  const key = `body_block_${index}`;

  const props = {
    key,
    mappedContentType,
  };

  const transforms = {
    /* eslint-disable react/prop-types */
    a: ({
      attribs: {
        id,
        href,
        name,
        rel,
        target,
        extraClassNames,
      },
      attribs: allAttributes,
      children,
    }) => {
      const classes = getClasses(allAttributes.class);

      return (
        <Link
          href={href}
          id={id}
          key={uniqid('link-')}
          name={name}
          rel={rel}
          target={target}
          extraClassNames={{
            ...extraClassNames,
            ...classes,
          }}
        >
          {processNodes(children)}
        </Link>
      );
    },
    /* eslint-enable */
  };

  switch (type) {
    case 'TEXT': {
      return (
        <Text span={false} {...props}>
          <HtmlSafe
            html={he.decode(data)}
            transforms={transforms}
          />
        </Text>
      );
    }

    case 'CEROS': {
      return (
        <Ceros
          html={he.decode(data)}
          key={`ceros-${index}`}
        />
      );
    }

    case 'HEADER': {
      const { size, text } = data;
      const headingSize = Number.parseInt(size, 10) || 3;

      return (
        <Heading
          size={headingSize}
          {...props}
        >
          {he.decode(text)}
        </Heading>
      );
    }

    case 'IMAGE': {
      props.credits = data.credit;
      const isValidTranfName = typeof data.size === 'string';
      const size = isValidTranfName ? data.size : 'content-image';

      return (
        <Image
          {...data}
          {...props}
          size={size}
          displayImageMetadata
        />
      );
    }

    case 'TABLE': {
      return (
        <Table
          {...props}
        >
          <HtmlSafe html={he.decode(data)} />
        </Table>
      );
    }

    case 'SUBSCRIBE': {
      const {
        breakpoints: {
          desktop: desktopBreakpoint,
          mobile: mobileBreakpoint,
        },
        viewport,
      } = block.props;

      return (
        <ViewportChecker
          range={viewport === 'desktop' ? desktopBreakpoint : mobileBreakpoint}
          key={uniqid('subscription-')}
        >
          {getPromobox({
            ...block.props,
            extraClassNames: {
              ...block.props.extraClassNames || {},
              '-content': true,
            },
          })}
        </ViewportChecker>
      );
    }

    case 'NEWSLETTER': {
      const {
        breakpoints: {
          desktop: desktopBreakpoint,
          mobile: mobileBreakpoint,
        },
        viewport,
      } = block.props;

      return (
        <ViewportChecker
          range={viewport === 'desktop' ? desktopBreakpoint : mobileBreakpoint}
          key={uniqid('newsletter-')}
        >
          {getNewsletter({
            ...block.props.newsletterConfig,
            extraClassNames: {
              ...block.props.newsletterConfig.extraClassNames || {},
              '-content': true,
            },
            ctaLabel: '',
          })}
        </ViewportChecker>
      );
    }

    case 'SUBSCRIBE_NEWSLETTER': {
      const {
        breakpoints: {
          desktop: desktopBreakpoint,
          mobile: mobileBreakpoint,
        },
        viewport,
      } = block.props;
      return (
        <ViewportChecker
          range={viewport === 'desktop' ? desktopBreakpoint : mobileBreakpoint}
          key={uniqid('subscription-newsletter-')}
        >
          {getNewsletter({
            ...block.props.newsletterConfig,
            extraClassNames: {
              ...block.props.newsletterConfig.extraClassNames || {},
              '-content': true,
            },
            ctaLabel: '',
          })}
        </ViewportChecker>
      );
    }

    case 'SHOPWINDOW': {
      const {
        props: {
          viewport,
        },
      } = block;

      if (viewport === 'mobile') return '';

      return (
        <ShopWindow />
      );
    }

    case 'IFRAME': {
      let iframeHeight = data.height;
      if (data.style) {
        // Sample: data.style = box-sizing: border-box; height: calc(500px); width: 770px;'
        const heightRegex = /(?:^|\s)height:(.*?);(?:\s|$)/;
        const heightsArr = data.style.match(heightRegex);
        iframeHeight = heightsArr && heightsArr.length > 1 ? heightsArr[1].trim() : data.height;
      }
      return (
        <IFrame
          key={uniqid()}
          iframeUrl={data.src}
          iframeHeight={iframeHeight}
          iframeWidth={data.width}
          scrolling={data.scrolling}
          {...props}
        />
      );
    }

    case 'SOCIAL_EMBED': {
      if (!data.html) {
        return false;
      }

      // Check for YouTube video
      if (data.provider_name === 'YouTube' && data.__data && data.__data.url) {
        return (
          <Video
            key={uniqid()}
            url={data.__data.url}
            lazyLoading
            {...props}
          />
        );
      }

      // Check for SoundCloud
      if (data.provider_name === 'SoundCloud') {
        // get iframe src
        const iframeURLRegex = new RegExp('src="(.*?)"', 'gm');
        const iframeURLMatch = iframeURLRegex.exec(data.html);

        if (!iframeURLMatch || !iframeURLMatch[1]) {
          return false;
        }

        const podCastData = {
          podcastUrl: iframeURLMatch[1],
          key,
        };

        return getPodcast(podCastData);
      }

      embedTypes.add(data.provider_name);

      return (
        <SocialEmbed
          {...props}
          type={data.provider_name}
          url={data.__data.url}
        >
          <HtmlSafe html={data.html} />
        </SocialEmbed>
      );
    }

    case 'FEATURED_RELATED_CONTENT': {
      const blockData = JSON.parse(block.data);

      if (blockData && blockData.length && Array.isArray(blockData)) {
        return blockData.map((bd, bdIndex) => (
          <p {...props} key={`featured_related_content_${bdIndex}`}>
            <Link href={bd.ctaUrl} extraClassNames={{ 'polaris__highlighted-link': true }}>
              {bd.image && bd.image.src && <Image src={bd.image.src} />}
              <span>{bd.title}</span>
            </Link>
          </p>
        ));
      }

      break;
    }

    case 'RELATED_CONTENT': {
      const blockData = JSON.parse(block.data);
      let componentData = {};
      if (blockData && blockData.length && Array.isArray(blockData)) {
        componentData = blockData.map(bd => (
          {
            url: bd.url || '',
            label: bd.label || '',
          }
        ));
      }
      return <RelatedLinks content={componentData} {...props} />;
    }

    case 'FEATURED_REVIEW': {
      const reviewData = JSON.parse(block.data);
      let featuredReviews = [];
      if (reviewData && reviewData.length && Array.isArray(reviewData)) {
        featuredReviews = reviewData.map((review, reviewIndex) => {
          const priceOptions = {
            min: get(review, 'priceMin', null),
            max: get(review, 'priceMax', null),
          };
          const rating = get(review, 'rating', null);
          const rounding = Number.isInteger(parseFloat(rating.toFixed(1))) ? 0 : 1;

          return getReviewBlock({
            reviewIndex,
            title: get(review, 'title', ''),
            url: get(review, 'url', ''),
            image: get(review, 'image', null),
            description: get(review, 'description', ''),
            allReviewsUrl: get(review, 'url', ''), // @TODO: Check with product team.
            allReviewsLabel: 'Read review',
            ratings: [{
              label: 'Carbuyer rating',
              value: rating,
              rounding,
            }],
            priceOptions,
          });
        });
      }

      return (featuredReviews && featuredReviews.length) ? featuredReviews : null;
    }

    case 'REVIEW': {
      const { rating, header, subtitle } = data;
      return getReviewList({
        title: header,
        summary: subtitle,
        overallRating: parseFloat(rating),
        showTextRating: false,
        overallRatingLabel: null,
        extraClassNames: { '-inline': true },
      });
    }

    case 'IndexBlock': {
      return <List content={block.items} {...props} />;
    }

    case 'DESKTOP_SIDE_ADVERT': {
      if (layoutType === 'commercialPage') return null;
      if (layoutType === 'commercialPageTitleImage') return null;

      return (
        <Ad
          {...block.props}
          {...props}
          extraClassNames={{
            ...block.props?.extraClassNames,
            '-sidebar-ad': true,
            'hide-mobile': true,
          }}
          isSkippable
          isRightSkip
        />
      );
    }

    case 'DESKTOP_INLINE_ADVERT':
    case 'MOBILE_INLINE_ADVERT': {
      if (layoutType === 'commercialPage') return null;
      if (layoutType === 'commercialPageTitleImage') return null;

      const platform = type === 'DESKTOP_INLINE_ADVERT' ? 'desktop' : 'mobile';

      return (
        <Ad
          {...block.props}
          {...props}
          extraClassNames={{
            ...block.props.extraClassNames,
            'hide-desktop': platform === 'mobile',
            'hide-tablet': platform === 'desktop',
            'hide-mobile': platform === 'desktop',
          }}
          isSkippable={platform === 'desktop'}
          targetViewport={platform}
          isSpaceReserved
        />
      );
    }

    case 'TEADS': {
      if (layoutType === 'commercialPage') return null;
      if (layoutType === 'commercialPageTitleImage') return null;

      return (
        <Ad
          {...block.props}
          {...props}
          key={`ad_teads_${index}`}
          isSkippable
          isTeads
        />
      );
    }

    case 'GALLERY_IN_DESCRIPTION_AD': {
      if (layoutType === 'commercialPage') return null;
      if (layoutType === 'commercialPageTitleImage') return null;

      return (
        <Ad
          {...block.props}
          {...props}
          key={uniqid('gallery-item-description-desktop-ad-')}
          id={`refresh-${uniqid()}`}
        />
      );
    }

    case 'ItemBlock': {
      switch (block.itemType) {
        case 'GALLERY_SLIDESHOW':
        case 'GALLERY_LINKED': {
          const media = block.item.images.filter(i => i.__typename !== 'LinkedMedia');
          return (
            <Gallery
              mode={block.itemType === 'GALLERY_SLIDESHOW' ? 'swipe' : 'linkout'}
              cover={block.item.teaserImage}
              url={block.item.url}
              media={media}
              viewAllLabel="View Gallery"
              viewAllUrl={block.item.url}
              {...props}
            />
          );
        }
        default: return null;
      }
    }

    case 'AUTHOR_AND_DISCLAIMER': {
      const {
        authors,
      } = block.options;

      const authorAndDisclaimer = [];
      if (authors) {
        const contributors = getContributorsFromAuthors(authors);
        if (contributors && contributors.length) {
          const siteLegalDisclaimer = getSiteLegalDisclaimer(contributors);
          if (siteLegalDisclaimer) {
            authorAndDisclaimer.push(siteLegalDisclaimer);
          }

          authorAndDisclaimer.push(getContributors({ contributors }));
        }
      }

      return (
        <React.Fragment key={uniqid()}>
          {authorAndDisclaimer}
        </React.Fragment>
      );
    }

    case 'DIGITEKA': {
      if (layoutType === 'commercialPage') return null;
      if (layoutType === 'commercialPageTitleImage') return null;

      return (
        getDigiteka({
          ...block.props,
          ...props,
        })
      );
    }

    default:
      return false;
  }

  return false;
};
