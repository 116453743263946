import PropTypes from 'prop-types';
import { globalSettingsShape, layoutDataShape, paginationShape } from 'Helpers/props-shapes';

const configShape = {
  config: PropTypes.shape({
    ...globalSettingsShape,
  }).isRequired,
};

export const searchPropsShape = {
  history: PropTypes.PropTypes.shape({
    push: PropTypes.func,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  setSearchPath: PropTypes.func.isRequired,
  ...configShape,
  ...layoutDataShape,
  ...paginationShape,
};
