import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import { PrimaryMedia } from '@autovia-uk/polaris-components/components/molecules/PrimaryMedia';

export const getPrimaryMedia = (primaryMedia) => {
  if (!primaryMedia) {
    return false;
  }

  const isSocialEmbed = primaryMedia
      && primaryMedia.socialBlockType
      && primaryMedia.socialBlockType === 'SOCIAL_EMBED';

  let primaryMediaProps = { ...primaryMedia };

  if (isSocialEmbed) {
    const embedData = JSON.parse(primaryMedia.data);
    const isPodcast = embedData && embedData.provider_name && embedData.provider_name === 'SoundCloud';

    if (isPodcast) {
      const iframeURLRegex = new RegExp('src="(.*?)"', 'gm');
      const iframeURLMatch = iframeURLRegex.exec(embedData.html);

      primaryMediaProps = {
        mediaType: 'Podcast',
        podcastData: {
          podcastUrl: iframeURLMatch[1],
        },
      };
    } else if (embedData && embedData.__data) {
      primaryMediaProps = {
        mediaType: 'SocialEmbed',
        provider: embedData.__data.type,
        url: embedData.__data.url,
        socialEmbed: '',
      };
    }
  }

  let lazyLoad = true;
  if (primaryMedia || primaryMedia?.page?.images[0]) {
    lazyLoad = false;
  }

  return (
    <PrimaryMedia
      key={uniqid()}
      {...primaryMediaProps}
      mediaLazyLoad={lazyLoad}
    />
  );
};

getPrimaryMedia.propTypes = {
  primaryMedia: PropTypes.shape({}).isRequired,
};
