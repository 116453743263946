/* eslint-disable react/prop-types */
import React from 'react';
import uniqid from 'uniqid';
import { Newsletter } from '@autovia-uk/polaris-components/components/organisms/Newsletter';

import 'Styles/components/organisms/_Newsletter.scss';

export const getNewsletter = ({
  props,
  newsletterConfig,
  additionalProps,
}) => {
  if ((typeof props !== 'object' || Object.keys(props).length === 0)
      && (typeof newsletterConfig !== 'object' || Object.keys(newsletterConfig).length === 0)) {
    return false;
  }

  // Ensure that modalOpen is not part of additional props being passed to Newsletter as it will cause conflicts.
  // @see https://creativesolutions.atlassian.net/browse/PDE-225
  const { modalOpen, ...extraProps } = additionalProps;

  const {
    mainTitle,
    title,
    ctaLabel,
    ctaUrl,
    placeholder,
    subtitle,
    extraClassNames,
  } = props;

  return (
    <Newsletter
      key={uniqid()}
      mainTitle={mainTitle || newsletterConfig.mainTitle}
      title={title || newsletterConfig.title}
      cta_label={ctaLabel || newsletterConfig.ctaLabel}
      formUrl={((ctaUrl || newsletterConfig.ctaUrl) || newsletterConfig.newsletterURL) || ''}
      placeholder={placeholder || newsletterConfig.placeholder}
      subtitle={subtitle || newsletterConfig.subtitle}
      extraClassNames={extraClassNames || {}}
      {...extraProps}
    />
  );
};
