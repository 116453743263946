/* eslint-disable react/prop-types */
import React from 'react';
import { Social } from '@autovia-uk/polaris-components/components/molecules/Social';

export const getSocial = ({
  extraClassNames,
  siteUrl,
  platforms,
  title,
  url,
}) => {
  if (siteUrl === null || url === null || title === null) {
    return false;
  }

  const defaultPlatforms = [
    {
      label: 'Share on Facebook',
      platform: 'facebook',
    },
    {
      label: 'Share on Twitter',
      platform: 'twitter',
    },
    {
      label: 'Share via Email',
      platform: 'email',
    },
  ];

  return (
    <Social
      url={`${siteUrl}${url}`}
      title={title}
      platforms={platforms || defaultPlatforms}
      extraClassNames={extraClassNames}
    />
  );
};
