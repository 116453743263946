import React from 'react';
import { compose } from 'recompose';
import { withPagination } from 'polaris-coreweb/exports';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { withJsonLD, withPreloadPrimaryImage } from 'Protons';
import { getRelatedContent } from 'SharedPartialsLocal/getRelatedContent';
import { setPageNumber } from '@autovia-uk/polaris-components/sharedHelpers/setPageNumber';
import { BaseComponent } from 'Templates';
import Helmet from 'Helpers/getHelmetForReview';

import { Review } from './Review';
import { ReviewQueryPagination as query } from './ReviewQuery.graphql';

const Composed = compose(
  setPageNumber,
  withPagination({
    query,
    path: ({ associatedContent }) => getRelatedContent({ related: associatedContent, key: 'indexArticlePageList' }),
  }),
  withJsonLD(Helmet),
  withPolaris,
  withPreloadPrimaryImage,
)(Review);

const ComposedWithBaseComponent = props => (
  <BaseComponent {...props}>
    <Composed {...props} />
  </BaseComponent>
);

export default ComposedWithBaseComponent;
