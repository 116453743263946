import React from 'react';
import { NextPages } from '@autovia-uk/polaris-components/components/molecules/NextPages';

export const getNextPagesForArticleAndReview = ({
  content,
  currentUrl,
  showIndex = true,
  showRating = false,
  showSubtitle = false,
  title,
}) => {
  if (Array.isArray(content) && content.length > 0) {
    return (
      <NextPages
        title={title}
        currentPageUrl={currentUrl}
        content={!showSubtitle ? content.map((item) => {
          const { subtitle: removedSubtitle, ...noSubtitle } = item;
          return noSubtitle;
        }) : content}
        showIndex={showIndex}
        showRating={showRating}
      />
    );
  }

  return null;
};
