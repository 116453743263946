import React from 'react';
import { getAuthorBio } from '@autovia-uk/polaris-components/sharedPartials/getAuthorBio';
import { getBreadcrumbs } from '@autovia-uk/polaris-components/sharedPartials/getBreadcrumbs';
import { getIndexPageItems } from '@autovia-uk/polaris-components/sharedPartials/getIndexPageItems';
import { getLoadMore } from '@autovia-uk/polaris-components/sharedPartials/getLoadMore';
import { useViewportType } from '@autovia-uk/polaris-components/components/protons/ViewportChecker';
import { authorPropsShape } from './props-shape';

import 'Styles/components/templates/_Author.scss';

const Author = ({
  layoutData: { page: { breadcrumbs, author } },
  pagination: {
    hasMore,
    isLoading,
    loadMore,
    nextUrl,
    pages,
    setPageRef,
  },
  config: {
    newsletterConfig,
    adBreakPoints,
    breakpoints,
  },
}) => {
  const config = {
    newsletterConfig: {
      ...newsletterConfig,
      extraClassNames: {
        '-light': true,
        '-small': true,
      },
    },
    adBreakPoints,
  };

  const { isMobile } = useViewportType({
    breakpointsMobile: breakpoints.mobile,
    breakpointsDesktop: breakpoints.desktop,
  });

  const loadMoreParams = {
    label: isLoading ? 'Loading...' : 'Load More',
    loadMore,
    isLoading,
    extraClassNames: {
      'polaris__load-more--small': true,
    },
    href: nextUrl,
  };

  const breadcrumbsTitle = 'AUTHOR';

  return (
    <div className="polaris__body">
      <main className="polaris__main -author-template" id="main">
        <div className="polaris__content polaris__core-content polaris__core-content-last">
          {getBreadcrumbs({ breadcrumbs })}
          <p className="polaris__breadcrumb--title">{breadcrumbsTitle}</p>
        </div>

        {author?.photo?.src && ( // add the empty block only if author has an image (height: 50px)
          <div className="polaris__content polaris__core-content -author-bio-top" />
        )}
        <div className="polaris__gray--bg">
          <div className="polaris__content polaris__gray--bg polaris__core-content">
            {getAuthorBio(author)}
          </div>
        </div>

        <div className="polaris__content polaris__core-content">
          <div data-previous="1" ref={setPageRef} />
          {getIndexPageItems({
            articlesPerRow: 4,
            config,
            pages,
            setPageRef,
            templateType: 'Author',
            isMobile,
          })}
          {hasMore && getLoadMore(loadMoreParams)}
        </div>
      </main>
    </div>
  );
};

Author.propTypes = {
  ...authorPropsShape,
};

export { Author };
