/**
 * Extracts authors for PostMeta component.
 * @param {Array} authors Array of authors from middleware.
 * @returns {Object}
 */
export function getAuthors(authors) {
  if (authors === false) {
    return false;
  }

  const author = {
    author: 'Driving Electric',
    authorUrl: '/authors/driving-electric',
  };

  const authorsList = [];
  const maxAuthors = 3;

  if (authors && Array.isArray(authors)) {
    if (authors.length === 1) {
      author.author = authors[0].name;
      author.authorUrl = authors[0].url;
      author.authorImage = { src: authors[0].image ? authors[0].image.src : '' };
    } else if (authors.length <= maxAuthors) {
      authors.forEach(({ name, url, image }, index) => {
        if (index < maxAuthors) {
          authorsList.push({ author: name, authorUrl: url, authorImage: { src: image ? image.src : '' } });
        }
      });
    } else {
      authorsList.push(
        { author: authors[0].name, authorUrl: authors[0].url, authorImage: { src: authors[0].image ? authors[0].image.src : '' } },
        { author: authors[1].name, authorUrl: authors[1].url, authorImage: { src: authors[1].image ? authors[1].image.src : '' } },
        { ...author },
      );
    }
  }

  return authorsList.length > 1 ? { authors: authorsList } : { ...author };
}
