import React from 'react';
import classNames from 'classnames';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { Hero } from '@autovia-uk/polaris-components/components/organisms/Hero';
import { ShortAuthorBio } from '@autovia-uk/polaris-components/components/molecules/ShortAuthorBio';
import injectionEngine from '@autovia-uk/injection-engine';
import rules from '@autovia-uk/injection-default-rules-set';
import { HtmlSafe } from 'polaris-coreweb/exports';
import { getArticleGroup } from '@autovia-uk/polaris-components/sharedPartials/getArticleGroup';
import { getAfterContentAd } from 'SharedPartialsLocal/getAfterContentAd';
import { getBody } from '@autovia-uk/polaris-components/sharedPartials/getBody';
import { getBreadcrumbs } from '@autovia-uk/polaris-components/sharedPartials/getBreadcrumbs';
import { getCommercialPageHeroImage } from '@autovia-uk/polaris-components/sharedPartials/getCommercialPageHeroImage';
import { getIndexArticlePageHeader } from '@autovia-uk/polaris-components/sharedPartials/getIndexArticlePageHeader';
import { getIndexArticlePageContent } from '@autovia-uk/polaris-components/sharedPartials/getIndexArticlePageContent';
import { getIndexArticlePageList } from '@autovia-uk/polaris-components/sharedHelpers/getIndexArticlePageList';
import { getMultipageNavigation } from '@autovia-uk/polaris-components/sharedPartials/getMultipageNavigation';
import { getNextPagesForArticleAndReview } from 'SharedPartialsLocal/getNextPagesForArticleAndReview';
import { getPostMeta } from 'SharedPartialsLocal/getPostMeta';
import { getSocial, getPrimaryMedia } from 'SharedPartialsLocal';
import { getSponsorInfo } from '@autovia-uk/polaris-components/sharedPartials/getSponsorInfo';
import { getTags } from '@autovia-uk/polaris-components/sharedPartials/getTags';
import { getRelatedContent } from 'SharedPartialsLocal/getRelatedContent';
import { getArticleGroupContent } from '@autovia-uk/polaris-components/sharedHelpers/getArticleGroupContent';
import { getMostPopularArticleGroup } from '@autovia-uk/polaris-components/sharedHelpers/getMostPopularArticleGroup';
import { countBodyInjectedInlineAds } from '@autovia-uk/polaris-components/sharedHelpers/countBodyInjectedInlineAds';
import { useViewportType } from '@autovia-uk/polaris-components/components/protons/ViewportChecker';
import { articlePropsShape } from './props-shape';

import 'Styles/components/templates/_Article.scss';

const Article = (props) => {
  const {
    config: {
      breakpoints,
      globalSettings: {
        adSettings,
        adSettings: {
          afterContentDesktopAds,
          afterContentMobileAds,
        },
        newsletterLink,
        promoBoxSettings,
      },
      newsletter: newsletterSettings,
      siteUrl,
    },
    layoutData: {
      page: {
        authors,
        associatedContent,
        body,
        breadcrumbs,
        filters,
        heroImage,
        isSponsored,
        kicker,
        layoutType,
        metaData,
        monetising,
        primaryMedia,
        showFilters,
        sponsor: sponsorInfo,
        subtitle,
        tags,
        title,
        url,
      },
      page,
    },
  } = props;

  const { isMobile } = useViewportType({
    breakpointsMobile: breakpoints.mobile,
    breakpointsDesktop: breakpoints.desktop,
  });

  const indexData = getIndexArticlePageList(associatedContent);
  const isIndexArticlePage = indexData !== false;

  const isCommercialPage = layoutType === 'commercialPage';
  const isCommercialPageTitleImage = layoutType === 'commercialPageTitleImage';
  const commercialPageHeroImage = getCommercialPageHeroImage({ heroImage });

  const promoBox = { ...promoBoxSettings, extraClassNames: { '-inline': true } };
  const newsletter = {
    props: {},
    portalNewsletterUrl: newsletterLink,
    configNewsletter: newsletterSettings,
    additionalProps: { extraClassNames: { '-inline': true } },
  };

  let bodyInjected = injectionEngine({
    body,
    props: {
      adSettings,
      authors,
      breakpoints,
      metaData,
      monetising,
      newsletterConfig: newsletter,
      pageContentType: 'article',
      promoBoxSettings: promoBox,
    },
    rules,
  });

  // replace SUBSCRIBE_NEWSLETTER with SHOPWINDOW block
  bodyInjected = bodyInjected && bodyInjected.map((item) => {
    if (item.type === 'SUBSCRIBE_NEWSLETTER') {
      return {
        ...item,
        ...{ type: 'SHOPWINDOW' },
      };
    }
    return { ...item };
  });

  const countInlineAds = countBodyInjectedInlineAds({
    bodyInjected,
    bodyBlockInlineAdDesktop: 'DESKTOP_INLINE_ADVERT',
    bodyBlockInlineAdMobile: 'MOBILE_INLINE_ADVERT',
  });

  const taxonomyFilters = Array.isArray(filters) ? filters.map(f => ({
    label: f.title,
    value: f.url,
  })) : null;

  const taxonomyFilterParams = {
    content: taxonomyFilters,
    currentPageUrl: url,
    label: 'Show me:',
    listModeOptions: {
      seeMoreLabel: 'See all',
      seeLessLabel: 'See less',
      icon: 'down',
    },
  };

  const multipage = getRelatedContent({ related: associatedContent, key: 'relatedPages' });

  const isAfterContentAds = afterContentDesktopAds || afterContentMobileAds;

  const shortAuthorBio = () => {
    if (!authors || !authors.length) return null;

    const author = authors[0];

    return (
      <ShortAuthorBio
        authorBio={<HtmlSafe html={author.shortBio || ''} />}
        authorLink={author.url}
        authorImage={author.image}
        authorName={author.name}
        authorRole={author.jobTitle}
      />
    );
  };

  const topComponents = (
    <div className="polaris__simple-grid--main">
      {getMultipageNavigation({
      content: multipage, currentUrl: url, showCurrentlyReadingText: true, stickyEnabled: true,
    })}
      {!(isCommercialPage || isCommercialPageTitleImage) && getPrimaryMedia(primaryMedia, '-article')}
      {!(isCommercialPage || isCommercialPageTitleImage) && getSponsorInfo({ sponsor: sponsorInfo })}
    </div>
  );

  return (
    <>
      <div
        className={classNames({
          polaris__body: true,
          polaris__commercialpage: isCommercialPage,
          polaris__commercialpagetitleimage: isCommercialPageTitleImage,
        })}
      >
        <main className="polaris__main" id="main">
          {(isCommercialPage || isCommercialPageTitleImage) && (
            <Hero
              images={commercialPageHeroImage}
              title={isCommercialPageTitleImage ? title : ''}
              extraClassNames={{
                'polaris__commercialpage-hero': true,
                'polaris__commercialpagetitleimage-hero': isCommercialPageTitleImage,
                // if there is no heroImage, we need to add a class to the hero
                // to make sure that the title is visible when there is no image (in case of title over image layout)
                '-no-image': !commercialPageHeroImage.length,
              }}
              renderPictureTag
            />
          )}

          <div className="polaris__core-content polaris__content">

            {
              (isCommercialPage || isCommercialPageTitleImage)
                ? (
                  <div>
                    <div className="polaris__commercialpage-sponsor">
                      {getSponsorInfo({
                        sponsor: {
                          ...sponsorInfo,
                          logo: {
                            ...sponsorInfo?.logo,
                            size: 'sponsor-medium',
                          },
                        },
                      })}
                    </div>
                    <div className="polaris__commercialpage-meta">
                      {isSponsored && getBreadcrumbs({ breadcrumbs: [], isSponsored, kicker: isSponsored ? kicker : '' })}
                      {getPostMeta(page)}
                    </div>
                  </div>

                )
                : getBreadcrumbs({ breadcrumbs, isSponsored, kicker })
            }

            {!isCommercialPageTitleImage && (
              <Heading size={1} extraClassNames={{ '-title': true }}>
                {title}
              </Heading>
              )}

            <Heading size={2} extraClassNames={{ '-subtitle': true }}>
              {subtitle}
            </Heading>

            {!(isCommercialPage || isCommercialPageTitleImage) && getPostMeta(page)}

          </div>

          <div className={`polaris__content${(isCommercialPage || isCommercialPageTitleImage) ? ' -full-width' : ''} -body-copy`}>
            {getBody({
                bodyInjected,
                props,
                template: 'article',
                layoutType,
                bottomComponents: [],
                topComponents,
                isMobile,
              })
            }

            {!(isCommercialPage || isCommercialPageTitleImage) && (
              <div className="polaris__core-content polaris__core-content-last polaris__simple-grid--main">
                {getNextPagesForArticleAndReview({
                  content: multipage,
                  currentUrl: url,
                  title: 'In This Review',
                  showRating: true,
                  showSubtitle: true,
                })}
                <div className="-tags-social">
                  {getTags({
                    tags,
                    checkTagsCondition: false,
                    extraClassNames: { '-article': true },
                  })}
                  {getSocial({
                    siteUrl,
                    url,
                    title,
                    extraClassNames: { '-article': true },
                  })}
                </div>
                {shortAuthorBio()}
              </div>
              )}
          </div>

          {!(isCommercialPage || isCommercialPageTitleImage) && isAfterContentAds && (getAfterContentAd({ afterContentDesktopAds, afterContentMobileAds }))}

          {!(isCommercialPage || isCommercialPageTitleImage) && (
            <div className="polaris__core-content polaris__core-content-first polaris__content">
              {getArticleGroup({
                title: 'Recommended',
                content: getArticleGroupContent({ related: associatedContent, key: 'recommended' }),
                articlesPerRow: 4,
                articleCardProps: {
                  datePosition: 'bottom',
                  excerpt: null,
                  isHeading: false,
                  showRating: true,
                },
              })}
              {
                !isIndexArticlePage
                && getMostPopularArticleGroup({
                  articleCardProps: {
                    datePosition: 'bottom',
                    isHeading: false,
                    showRating: true,
                  },
                  associatedContent,
                  nativeAd: 'native_article',
                })
              }
              {isIndexArticlePage && (
                <>
                  <div className="polaris__article-group -header">
                    {getIndexArticlePageHeader({
                      indexData,
                      propsData: props,
                      showFilters: showFilters && taxonomyFilters !== null && taxonomyFilters.length > 0,
                      taxonomyFilterParams,
                    })}
                  </div>
                  {getIndexArticlePageContent(
                    indexData,
                    {
                      ...props,
                      articlesPerRow: 4,
                      articleCardProps: { datePosition: 'bottom' },
                    },
                    countInlineAds,
                    isMobile,
                  )}
                </>
                )}
            </div>
          )}
        </main>
      </div>
    </>
  );
};

Article.propTypes = {
  ...articlePropsShape,
};

export { Article };
