import React, { Fragment } from 'react';
import uniqid from 'uniqid';
import { getHomeContentBlocks } from './homeHelpers';

import 'Styles/components/templates/_Home.scss';

import { homePropsShape } from './props-shape';

const Home = ({
  layoutData: { page: { associatedContent } },
  config: {
    newsletterConfig,
    globalSettings: {
      promoBoxSettings,
    },
  },
}) => {
  const config = {
    promoBoxConfig: promoBoxSettings,
    newsletterConfig,
  };
  const components = getHomeContentBlocks(associatedContent, config);

  return (
    <div className="polaris__body">
      <main className="polaris__main" id="main">
        {components && components.length && Array.isArray(components) && components.map((component, index) => (
          <Fragment key={uniqid(`component_${index}`)}>
            {component}
          </Fragment>
        ))}
      </main>
    </div>
  );
};

Home.propTypes = {
  ...homePropsShape,
};
Home.defaultProps = {};

export { Home };
