/* eslint-disable react/prop-types */
import React from 'react';
import get from 'lodash.get';
import { ReportCard } from '@autovia-uk/polaris-components/components/organisms/ReportCard';
import { getPrimaryMedia } from 'SharedPartialsLocal/getPrimaryMedia';
import { getRelatedContent } from 'SharedPartialsLocal/getRelatedContent';

import 'Styles/components/organisms/_ReportCard.scss';

export const getReportCard = ({ props }) => {
  const {
    config: {
      rating: {
        icon,
      },
    },
    layoutData: {
      page: {
        associatedContent,
        pageId,
        primaryMedia,
        rating,
        resources,
        specsPath,
        vehicleSpecList: {
          fuelTypes,
          priceRange: {
            capPriceMin,
            capPriceMax,
          },
        },
      },
    },
  } = props;

  const ctas = [];
  const extras = [];
  const ratings = [];
  const relatedContent = getRelatedContent({ related: associatedContent, key: 'relatedPages' });

  let overAllRating = rating;
  let tabRating;
  const priceOptions = {
    min: capPriceMin,
    max: capPriceMax,
  };
  const rounding = 1;

  if (relatedContent !== null && relatedContent.length > 0 && relatedContent[0].teaserId !== pageId) {
    overAllRating = relatedContent[0].rating || 0;
    const currentSection = relatedContent.filter(item => item.teaserId === pageId);
    if (currentSection && Array.isArray(currentSection) && currentSection.length) {
      tabRating = {
        showTextRating: true,
        value: rating,
        label: `${currentSection[0].title || 'This Section'} rating`,
        rounding,
      };
    }
  }

  if (overAllRating > 0) {
    ratings.push({
      label: 'Overall rating',
      value: overAllRating,
      showTextRating: true,
    });
  }

  if (fuelTypes && fuelTypes.length > 0) {
    extras.push({
      label: `Fuel Type${fuelTypes.length > 1 ? 's:' : ':'}`,
      value: fuelTypes.join(', '),
    });
  }

  if (tabRating) {
    ratings.push(tabRating);
  }

  const awardLogo = get(resources, 'award.logo', false);
  const awardName = get(resources, 'award.name', 'Badge');
  let awardMedia = {};
  if (awardLogo) {
    awardMedia = {
      badge: {
        ...awardLogo,
        alt: awardName,
      },
    };
  }

  if (specsPath) {
      ctas.push({
      url: specsPath,
      title: 'Price & Specification',
    });
  }

  return (
    <ReportCard
      ctas={ctas}
      extras={extras}
      extraComponent={getPrimaryMedia(Object.assign(awardMedia, primaryMedia))}
      ratings={ratings}
      priceOptions={priceOptions}
      priceCtaLabel=""
      priceCtaUrl=""
      prosIcon="polaris__icon-plus"
      consIcon="polaris__icon-minus"
      prosLabel="polaris__icon-plus"
      consLabel="polaris__icon-minus"
      /* eslint-disable-next-line */
      starsPath={icon.iconPath || 'M5.24,22.4,12,17.49l6.76,4.91-2.58-7.94,6.75-4.92H14.58L12,1.6,9.42,9.54H1.06l6.76,4.92Z'}
      starsViewBox={icon.iconViewBox || '0 0 24 24'}
      starsDimensions={icon.iconDimensions || '24px'}
      starsSpacings={icon.iconSpacing || '1px'}
      showProsCons={false}
      extraClassNames={{
        '-article': true,
      }}
      ratingsRounding={rounding}
    />
  );
};
