/* eslint-disable react/prop-types */
import React from 'react';
import get from 'lodash.get';
import { NextSteps } from '@autovia-uk/polaris-components/components/molecules/NextSteps';

export const getNextSteps = ({ props, title = 'Next Steps' }) => {
  const brochureLink = get(props, 'vehicleNextSteps.brochure', '');
  const testDriveLink = get(props, 'vehicleNextSteps.testDrive', '');
  const dealerLink = get(props, 'vehicleNextSteps.dealer', '');

  const nextSteps = [];
  if (testDriveLink) {
    nextSteps.push({
      url: testDriveLink,
      title: 'Book a test drive',
    });
  }

  if (brochureLink) {
    nextSteps.push({
      url: brochureLink,
      title: 'Download a brochure',
    });
  }

  if (dealerLink) {
    nextSteps.push({
      url: dealerLink,
      title: 'Find a dealer',
    });
  }

  if (nextSteps.length === 0) {
    return null;
  }

  return (
    <NextSteps
      title={title}
      urls={nextSteps}
    />
  );
};
