import React from 'react';
import get from 'lodash.get';
import uniqid from 'uniqid';
import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { MultiPageNavigation } from '@autovia-uk/polaris-components/components/molecules/MultiPageNavigation';

import { getArticleGroup } from '@autovia-uk/polaris-components/sharedPartials/getArticleGroup';
import { getBreadcrumbs } from '@autovia-uk/polaris-components/sharedPartials/getBreadcrumbs';
import { getGoTo } from 'SharedPartialsLocal/getGoTo';
import { getReviewMultipageNavigation } from '@autovia-uk/polaris-components/sharedPartials/getReviewMultipageNavigation';
import { getSocial } from 'SharedPartialsLocal/getSocial';
import { getTags } from '@autovia-uk/polaris-components/sharedPartials/getTags';

import { getInlineAdConfig } from '@autovia-uk/polaris-components/sharedHelpers/getInlineAdConfig';
import { getRelatedContent } from 'SharedPartialsLocal/getRelatedContent';
import { getSimilarProductContent } from '@autovia-uk/polaris-components/sharedHelpers/getSimilarProductContent';
import { getMostPopularArticleGroup } from '@autovia-uk/polaris-components/sharedHelpers/getMostPopularArticleGroup';
import { getMultipageVehicleSpecs } from '@autovia-uk/polaris-components/sharedHelpers/getMultipageVehicleSpecs';
import { getReviewTitleFromSpecPage } from 'Helpers/getReviewTitleFromSpecPage';
import { getReviewURLFromSpecPage } from 'Helpers/getReviewURLFromSpecPage';
import { getRightSideAdConfig } from '@autovia-uk/polaris-components/sharedHelpers/getRightSideAdConfig';

import VehicleSpecs from 'Templates/spec/VehicleSpecs';

import { specPropsShape } from './props-shape';
import 'Styles/components/templates/_Specs.scss';

const SpecTemplate = (props) => {
  const {
    config: {
      siteUrl,
    },
    layoutData: {
      page: {
        title,
        url,
        subtitle,
        kicker,
        isSponsored,
        associatedContent,
        breadcrumbs,
        vehicleSpecs,
        vehicleSpecList,
        tags,
      },
    },
  } = props;

  const trims = get(vehicleSpecList, 'trims', []);
  const multipage = getRelatedContent({ related: associatedContent, key: 'relatedPages' });

  return (
    <>
      <div className="polaris__body">
        <main className="polaris__main" id="main">
          <div className="polaris__core-content polaris__content">
            { getBreadcrumbs({ breadcrumbs, isSponsored, kicker }) }
            <Heading size={1} extraClassNames={{ '-title': true }}>
              { title }
            </Heading>
            <Heading size={2} extraClassNames={{ '-subtitle': true }}>
              { subtitle }
            </Heading>
            {getGoTo({
              url: getReviewURLFromSpecPage(url, 'specs'),
              title: getReviewTitleFromSpecPage(title, 'specs'),
              icon: 'polaris__ico-bolt',
            })}
          </div>
          <div className="polaris__core-content polaris__content">
            {getReviewMultipageNavigation({
              multipage,
              currentUrl: url,
              nextIcon: 'polaris__ico-right-chevron',
              prevIcon: 'polaris__ico-left-chevron',
            })}
          </div>
          {vehicleSpecs && (
            <>
              <main className="polaris__core-content polaris__content polaris__simple-grid -body-copy">
                <div className="polaris__simple-grid--aside">
                  <Ad {...getRightSideAdConfig(1).props} />
                </div>
                <div className="polaris__simple-grid--main">
                  <MultiPageNavigation
                    selectedUrl={url}
                    content={getMultipageVehicleSpecs(trims)}
                    showCurrentlyReadingText
                    showIndex
                  />
                </div>

                <VehicleSpecs vehicleSpecs={vehicleSpecs} />

                <div className="polaris__simple-grid--aside">
                  <Ad {...getRightSideAdConfig(2).props} />
                </div>
                <div className="polaris__simple-grid--full">
                  <Ad
                    isSpaceReserved
                    key={uniqid('ad-')}
                    {...getInlineAdConfig(2).props}
                  />
                </div>
                <div className="polaris__core-content polaris__simple-grid--main">
                  <div className="-tags-social">
                    {getTags({
                      tags,
                      checkTagsCondition: false,
                      extraClassNames: { '-article': true },
                    })}
                    {getSocial({
                      siteUrl,
                      url,
                      title,
                      extraClassNames: { '-article': true },
                    })}
                  </div>
                </div>
              </main>
            </>
          )}
          {/* Render Associated Contents */}
          <div className="polaris__core-content polaris__content">
            {getArticleGroup({
              title: 'Have You Considered?',
              content: getSimilarProductContent({ related: associatedContent, key: 'recommended' }),
              articlesPerRow: 4,
              articleCardProps: {
                datePosition: 'bottom', showRating: true, rating: 5, excerpt: null,
              },
              extraClassNames: { 'polaris__review--consider': true },
            })}
            {getMostPopularArticleGroup({
              articleCardProps: {
                datePosition: 'bottom',
                isHeading: false,
                showRating: true,
              },
              associatedContent,
              nativeAd: 'native_article',
            })}
          </div>
        </main>
      </div>
    </>
  );
};

SpecTemplate.propTypes = {
  ...specPropsShape,
};

export { SpecTemplate };
